<template>
  <!--begin::Post-->
  <div class="post" id="kt_post">
    <!--begin::Container-->
    <div id="kt_content_container" class="container-xxl">
      <div class="card mb-10">
        <div class="card-body">
          <div class="row">
            <div class="col pt-3 text-bold">
              <h4>Silahkan pilih user dan satker yang akan di tugaskan</h4>
            </div>
            <div class="col-auto">
              <button class="btn btn-primary" @click="confirmPicSelection">
                Set PIC
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <!--begin::Card-->
        <div class="card col-6">
          <!--begin::Card body-->
          <div class="card-body">
            <BaseTable
              :columns="columnsUser"
              :service="'user'"
              :defaultFilter="userSearchParams"
              ref="tableUser"
              @actionButton="handleAction"
              @selectedRows="handleSelected"
            >
              <template #header>
                <div class="row">
                  <div class="col">
                    <h4 class="mt-5">Data User</h4>
                  </div>
                  <div class="col">
                    <input
                      type="text"
                      name="search"
                      placeholder="Search..."
                      class="form form-control"
                      v-model="userSearchParams.search"
                    />
                  </div>
                </div>
              </template>
            </BaseTable>
          </div>
          <!--end::Card body-->
        </div>
        <!--end::Card-->
        <!--begin::Card-->
        <div class="card col-6">
          <!--begin::Card body-->
          <div class="card-body">
            <BaseTable
              :columns="columns"
              :service="'satker?satker=1'"
              :defaultFilter="satkerSearchParams"
              ref="tableSatker"
              @actionButton="handleAction"
              @selectedRows="handleSelected"
            >
              <template #header>
                <div class="row">
                  <div class="col">
                    <h4 class="mt-5">Data Satker</h4>
                  </div>
                  <!-- <div class="col">
                    <select
                      name=""
                      id=""
                      class="form-control"
                      v-model="satkerSearchParams.is_pic"
                    >
                      <option value="">Semua</option>
                      <option value="1">Sudah Punya PIC</option>
                      <option value="2">Belum Punya PIC</option>
                    </select>           
                  </div> -->
                  <div class="col">
                    <input
                      type="text"
                      name="search"
                      placeholder="Search..."
                      class="form form-control"
                      v-model="satkerSearchParams.search"
                    />
                  </div>
                </div>
                <div class="mt-5">
                  <v-select
                    :filterable="true"
                    :options="satkerOptions"
                    @search="onSearch"
                    v-model="satkerSearchParams.satker_parent"
                    @option:selected="hanldeSatkerSelected"
                  >
                  </v-select>
                </div>
              </template>

              <template #action-button="{ data }">
                <div v-if="data.value.users">
                  <div v-for="(v, k) in data.value.users" :key="k">
                    <button
                      class="btn btn-sm btn-secondary"
                      @click="confirmRemove(data.value, v)"
                    >
                      {{ v.username }}
                    </button>
                  </div>
                </div>
              </template>
            </BaseTable>
          </div>
          <!--end::Card body-->
        </div>
        <!--end::Card-->
      </div>
    </div>
    <!--end::Container-->
  </div>
  <!--end::Post-->
</template>

<script>
import { toRaw } from "vue";
import Service from "@/services/base.service";
import BaseTable from "/src/components/BaseTable.vue";
import "vue-select/dist/vue-select.css";

export default {
  components: {
    BaseTable,
  },
  data() {
    return {
      loading: false,
      columns: [
        { field: "id", title: "ID", isUnique: true },
        { field: "actions", title: "PIC" },
        { field: "satkerid", title: "satkerid" },
        { field: "kdsatker", title: "kdsatker" },
        // { field: "satkerid_baru", title: "satkerid_baru" },
        // { field: "satker", title: "satker" },
        { field: "namasingkat", title: "namasingkat" },
        // { field: "jobgrade", title: "jobgrade" },
        // { field: "namajabatan", title: "namajabatan" },
        // { field: "nourut", title: "nourut" },
        // { field: "iskotama", title: "iskotama" },
        // { field: "issatminkal", title: "issatminkal" },
        // { field: "tipe_org", title: "tipe_org" },
        // { field: "havechild", title: "havechild" },
        // { field: "jabatanid", title: "jabatanid" },
        // { field: "actions", title: "Actions" },
      ],
      columnsUser: [
        // { field: "id", title: "ID", isUnique: true, type: "number" },
        { field: "username", title: "Username" },
        { field: "email", title: "Email" },
        // { field: "actions", title: "Actions" },
      ],
      userSearchParams: {
        search: "",
      },
      satkerSearchParams: {
        search: "",
        is_pic: "",
        satker_parent: [],
      },
      satkerOptions: [],
    };
  },
  watch: {
    satkerSearchParams: {
      handler() {
        this.$refs.tableSatker.reload();
      },
      deep: true,
    },
    userSearchParams: {
      handler() {
        this.$refs.tableUser.reload();
      },
      deep: true,
    },
  },
  mounted() {
    // this.getListData();
  },
  methods: {
    handleAction(value) {
      switch (value.type) {
        case "create":
          this.$router.push({ name: "satker-create" });
          break;
        case "view":
          this.$router.push({
            name: "satker-update",
            params: { id: value.data.id },
          });
          break;
        case "delete":
          this.deleteConfim(value.data.id);
          break;
        default:
          break;
      }
      console.log("action", value);
    },
    deleteConfim(id) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.deleteAction(id);
          }
        });
    },
    async deleteAction(id) {
      try {
        const BaseService = new Service("satker");
        await BaseService.deleteData(id);
        this.$refs.table.reload();
      } catch (error) {
        console.log(error);
      }
    },
    handleSelected(value) {
      console.log("selected", value);
    },
    confirmPicSelection() {
      const userSelected = this.$refs.tableUser.getSelectedRows();
      const satkerSelected = this.$refs.tableSatker.getSelectedRows();
      if (!userSelected.length || !satkerSelected.length) {
        this.$swal.fire({
          icon: "error",
          text: "Silahkan pilih user dan satker",
        });
        return;
      }
      const listUser = userSelected.map((r) => r.username);
      const listSatker = satkerSelected.map((r) => r.namasingkat);

      this.$swal
        .fire({
          title: "Konfirmasi?",
          text: `User ini ${listUser.toString()} dijadikan sebagai PIC: ${listSatker.toString()}`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ya, Submit!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.doAction(
              userSelected.map((r) => r.id),
              satkerSelected.map((s) => s.id)
            );
          }
        });
    },
    confirmRemove(satker, user) {
      const satkerSelected = toRaw(satker);
      const userSelected = toRaw(user);

      this.$swal
        .fire({
          title: "Konfirmasi!",
          text: `Hapus ${userSelected.username} dari pic satker ${satkerSelected.namasingkat}?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ya!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.doAction([userSelected.id], [satkerSelected.id], 1);
          }
        });
    },
    async doAction(userSelected, satkerSelected, isRemove = null) {
      const service = new Service("user/satker");
      const result = await service.createData({
        user_ids: userSelected,
        satker_ids: satkerSelected,
        unset: isRemove,
      });
      this.$refs.tableSatker.reload();
      return result;
    },
    onSearch(search, loading) {
      if (search.length) {
        loading(true);
        const service = new Service("satker");
        service.getListData({ search }).then(({ data }) => {
          this.satkerOptions = data.map((m) => {
            return {
              label: `${m.satkerid} - ${m.namasingkat}`,
              value: m.id,
              satkerid: m.satkerid,
            };
          });
          loading(false);
        });
      }
    },
    hanldeSatkerSelected(value) {
      const raw = toRaw(value);
      this.satkerSearchParams.satkerid = raw.satkerid;
      // this.$refs.tableSatker.reload();
    },
  },
};
</script>
